import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Button from "../components/button";

const ThankYouPage = () => {
  return (
    <Layout>
      <Seo title="Thank You" />
      <main className="container mx-auto">
        <div className="flex flex-col items-center text-center lg:py-28 md:py-20 py-16">
          <h1 className="font-display lg:text-display-2xl md:text-display-xl text-display-md md:pb-4 pb-2">
            Thanks for reaching out!
          </h1>
          <p className="md:text-body-lg text-body-md pb-10 text-neutral-700">
            We will return your message as soon as possible.
          </p>
          <a href="/#top">
            <Button label="BACK TO HOME" size="lg" icon={false} />
          </a>
        </div>
      </main>
    </Layout>
  );
};

export default ThankYouPage;
